import React, { useEffect } from "react";

const FacebookChat = () => {
  useEffect(() => {
    // Add the Facebook SDK script to the document
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    window.fbAsyncInit = function () {
      window.FB.init({
        xfbml: true,
        version: "v13.0",
      });
    };
  }, []);

  return (
    <>
      <div id="fb-root"></div>
      <div
        className="fb-customerchat"
        attribution="page_inbox"
        page_id="YOUR_PAGE_ID" // Replace with your Facebook page ID
        theme_color="#0084ff" // Optional: Change the color theme of the chat
        logged_in_greeting="Hi! How can we help you?" // Optional: Greeting for logged-in users
        logged_out_greeting="Hi! How can we help you?" // Optional: Greeting for logged-out users
      ></div>
    </>
  );
};

export default FacebookChat;
