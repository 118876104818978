import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import productsData from "../components/productsData";
import styled from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";
import emailjs from "@emailjs/browser";
import { purchase } from "../utils/facebookPixelEvents";
/* global gtag */

const CheckoutPage = () => {
  const { name } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("Kosovë");
  const [shippingPrice, setShippingPrice] = useState(0);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const animation = useAnimation();

  // Extract quantity from query parameters
  const query = new URLSearchParams(location.search);
  const quantity = parseInt(query.get("quantity")) || 1; // Default to 1 if no quantity is provided

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const productData = productsData.find((item) => item.name === name);
    setProduct(productData);
  }, [name]);

  useEffect(() => {
    if (product) {
      document.title = `Blej ${product.name} | 21 Ditë Produkt`;
    }
  }, [product]);

  useEffect(() => {
    if (isInView) {
      animation.start("visible");
    }
  }, [isInView, animation]);

  const handleShippingChange = (e) => {
    const selectedOption = e.target.value;
    if (product && product.name === "Çaji 21 Ditë dobësohu") {
      setShippingPrice(0);
    } else if (selectedOption === "Kosovë") {
      setShippingPrice(0);
    } else if (selectedOption === "Shqipëri" || selectedOption === "Maçedoni") {
      setShippingPrice(3);
    } else {
      setShippingPrice(0);
    }
  };

  if (!product) {
    return <div>Loading...</div>;
  }

  // Calculate price based on quantity
  const productPrice =
    name === "Kremi për akne"
      ? quantity === 2
        ? 39.9
        : 24.9
      : parseFloat(
          product.discountPrice ? product.discountPrice : product.price
        );
  const totalPrice = productPrice + shippingPrice;

  const handleOrder = (e) => {
    e.preventDefault();

    const form = e.target;

    emailjs
      .sendForm(
        "service_80aig4u",
        "template_2osbvog",
        form,
        "u_41kRZzICRMIjAy6"
      )
      .then(
        (result) => {
          console.log(result.text);
          navigate("/thank-you-page");
          purchase();
          gtag("event", "purchase", {
            event_category: "ecommerce",
            event_label: "Order Placed",
          });
        },
        (error) => {
          console.log(error.text);
          alert("Failed to place order.");
        }
      );
  };

  return (
    <Container>
      <div className="left">
        <div className="item">
          <img src={product.image} alt={product.name} />
          <span>
            <h3>{product.name}</h3>
            <h2>€{productPrice.toFixed(2)}</h2>
          </span>
        </div>
        <div className="total">
          <span>
            <h3>Subtotal</h3>
            <h2>€{productPrice.toFixed(2)}</h2>
          </span>
          <span>
            <h3>Transporti</h3>
            {shippingPrice === 0 ? (
              <h2>FALAS</h2>
            ) : (
              <h2>€{shippingPrice.toFixed(2)}</h2>
            )}
          </span>
          <hr />
          <span>
            <h2>Totali</h2>
            <h1>€{totalPrice.toFixed(2)}</h1>
          </span>
        </div>
      </div>
      <hr />
      <form ref={ref} className="right" onSubmit={handleOrder}>
        <p>
          <b>Note:</b> Pagesa bëhet pasi ta merrni porosinë*
        </p>
        <p>
          <b>Oferte e limituar:</b> 1 Krem - €24.90, 2 Krema €39.90{" "}
        </p>
        <div className="contact">
          <label>Detajet e Porosisë</label>
          <div className="delivery">
            <input
              name="name"
              type="text"
              placeholder="Emri dhe mbiemri*"
              required
            />
            <span>
              <input
                type="number"
                name="phoneNumber"
                placeholder="Numri i telefonit*"
                required
              />
              <select
                onChange={(e) => {
                  handleShippingChange(e);
                  setSelectedCountry(e.target.value);
                }}
                name="country"
                required
              >
                <option value="Kosovo">Kosovë</option>
                <option value="Shqipëri">Shqipëri</option>
                <option value="Maçedoni">Maçedoni</option>
              </select>
            </span>
            <span>
              <input name="city" type="text" placeholder="Qyteti*" required />
              <input
                name="address"
                type="text"
                placeholder="Addresa*"
                required
              />
            </span>
            <textarea
              name="message"
              cols="10"
              rows="4"
              placeholder="Mezashi (Optionale)"
            ></textarea>
            <input type="hidden" name="productName" value={product.name} />
            <input
              type="hidden"
              name="productPrice"
              value={productPrice.toFixed(2)}
            />
            <input
              type="hidden"
              name="totalPrice"
              value={totalPrice.toFixed(2)}
            />
            <button type="submit">POROSIT TANI</button>
          </div>
        </div>
      </form>
    </Container>
  );
};

const Container = styled.div`
  padding: 7em 7%;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 7%;
  margin: auto;
  max-width: 1500px;
  hr {
    height: 70vh;
    border: 1px solid #dfdfdf;
  }
  .left {
    display: flex;
    flex-direction: column;
    align-items: end;
    flex: 3;
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      gap: 10px;
      padding: 10px;
      border-bottom: 1px solid #e4e0db;
      width: 100%;
      text-align: end;
      img {
        height: 200px;
        width: auto;
        border-radius: 0px;
        object-fit: contain;
        background-color: white;
      }
      span {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: end;
        width: 100%;
        h4 {
          text-transform: capitalize;
          font-size: 17px;
          font-weight: 300;
          color: gray;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 7px;
          span {
            color: #3f3f3f;
            font-weight: 500;
          }
        }
        h3 {
          text-transform: capitalize;
          font-weight: 500;
          color: #6b6b6b;
        }
        h2 {
          margin-top: 10px;
          font-weight: 500;
          color: #747474;
        }
      }
    }
    .total {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      margin-top: 3em;
      hr {
        border: none;
        height: 1px;
        border-bottom: 1px solid #dad3d0;
      }
      span {
        display: flex;
        justify-content: space-between;
        h3 {
          font-weight: 400;
          color: #797979;
        }
        h2 {
          font-weight: 500;
          font-size: 19px;
          color: #929292;
        }
        h1 {
          font-size: 20px;
          font-weight: 600;
          color: #575250;
        }
      }
    }
  }
  .right {
    margin-top: 2%;
    flex: 4;
    display: flex;
    flex-direction: column;
    gap: 2em;
    p {
      color: gray;
    }
    div {
      display: flex;
      flex-direction: column;
      gap: 7px;
    }
    span {
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: 10px;
    }
    input,
    textarea,
    select {
      font-family: "Montserrat", sans-serif;
      outline: none;
      border: 1px solid #dfdfdf;
      font-size: 17px;
      border-radius: 7px;
      padding: 14px;
      background: none;
      color: gray;
      width: 100%;
    }
    label {
      font-weight: 500;
      font-size: 20px;
    }
    .shipping {
      background-color: var(--secondaryBackgroundColor);
      padding: 20px 30px;
      border-radius: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      span {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        h3 {
          font-size: 20px;
          font-weight: 500;
        }
        h4 {
          font-weight: 300;
        }
      }
    }
    button {
      margin: auto;
      font-family: "Poppins", sans-serif;
      font-size: 18px;
      background: #278527c1;
      color: white;
      border: none;
      padding: 10px 14px;
      cursor: pointer;
      transition: 0.4s;
      margin: 1em;
      &:hover {
        background: #1b691bc1;
      }
    }
  }
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 2em;
    hr {
      height: 1px;
    }
    .left {
      .item {
        img {
          width: 45%;
        }
      }
      .total {
      }
    }
  }
`;

export default CheckoutPage;
