import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import productsData from "../components/productsData";
import { motion, useAnimation, useInView } from "framer-motion";

const ProductPage = () => {
  const { name } = useParams();
  const [quantity, setQuantity] = useState(1); // State for quantity
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const animation = useAnimation();

  const product = productsData.find((item) => item.name === name);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `${name} | 21 Ditë Produkt`;
  }, [name]);

  useEffect(() => {
    if (isInView) {
      animation.start("visible");
    }
  }, [isInView]);

  // Calculate price based on quantity
  const singlePrice =
    name === "Kremi për akne"
      ? quantity === 2
        ? 39.9
        : 24.9
      : parseFloat(product.price);
  const originalTotal = singlePrice * quantity;
  const finalPrice =
    name === "Kremi për akne" && quantity === 2 ? 39.9 : originalTotal;

  return (
    <Container>
      {product && (
        <>
          <motion.img
            ref={ref}
            variants={{
              hidden: { opacity: 0, scale: 0.7 },
              visible: { opacity: 1, scale: 1 },
            }}
            initial="hidden"
            animate={animation}
            transition={{ duration: 0.2, delay: 0.2, type: "spring" }}
            src={product.image}
            alt={product.name}
          />
          <motion.div
            className="right"
            variants={{
              hidden: { opacity: 0, x: -200 },
              visible: { opacity: 1, x: 0 },
            }}
            initial="hidden"
            animate={animation}
            transition={{ duration: 0.4, delay: 0.5, type: "spring" }}
          >
            <p>Oferte e limituar: 1 Krem - €24.90, 2 Krema €39.90 </p>
            <h4>21 Ditë Produkt</h4>
            <h2>{product.name}</h2>
            {name === "Kremi për akne" && quantity === 2 ? (
              <span>
                <h2 style={{ textDecoration: "line-through" }}>€49.80</h2>
                <h1>€39.90</h1>
              </span>
            ) : (
              <>
                {product.discountPrice ? (
                  <span>
                    <h2>€{product.price}</h2>
                    <h1>€{product.discountPrice}</h1>
                  </span>
                ) : (
                  <h1>€{product.price}</h1>
                )}
              </>
            )}
            {name === "Kremi për akne" && (
              <div>
                <label>Sasia:</label>
                <select
                  value={quantity}
                  onChange={(e) => setQuantity(parseInt(e.target.value))}
                >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                </select>
              </div>
            )}
            <h3>
              {product.unavailable ? (
                <span className="outOfStock">Out of Stock</span>
              ) : (
                <span>In Stock</span>
              )}
            </h3>
            <p>{product.description}</p>
            <div className="btns">
              <Link
                to={`/produktet/checkout/${product.name}?quantity=${quantity}`}
              >
                <button>VAZHDO TE POROSIA</button>
              </Link>
            </div>
          </motion.div>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 7em 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  color: #5f5f5f;
  transition: 0.5s;
  img {
    width: 49%;
    max-height: 80vh;
    object-fit: contain;
    transition: 0.5s;
  }
  .right {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    span {
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 500;
      h4 {
        font-weight: 300;
      }
      h2 {
        font-weight: 300;
        color: gray;
        font-size: 1.5em;
        text-decoration: line-through;
      }
      h1 {
        color: #278527c1;
      }
    }
    h2 {
      font-size: 2em;
      font-family: "Josefin Sans", sans-serif;
      font-weight: 500;
      text-transform: capitalize;
      color: #278527c1;
    }
    h3 {
      font-weight: 400;
      span {
        color: #585858;
      }
    }
    h1 {
      font-size: 2em;
      font-weight: 400;
      color: gray;
    }
    p {
      color: #a0a0a0;
      line-height: 1.6em;
      font-weight: 300;
      font-size: 18px;
    }
    button {
      margin-top: 1em;
      font-family: "Montserrat", sans-serif;
      color: #85766e;
      font-size: 1.2em;
      padding: 10px 30px;
      background: none;
      border: 1px solid #b9aea7;
      cursor: pointer;
      transition: 0.4s;
      &:hover {
        background: #278527c1;
        color: white;
      }
    }
  }
  @media (max-width: 730px) {
    flex-direction: column;
    .right {
      text-align: center;
      align-items: center;
      width: 100%;
    }
    img {
      width: 100%;
      flex-direction: column;
      align-items: center;
      max-height: 40vh;
    }
  }
  @media (max-width: 550px) {
    .imageDiv {
      /* img {
        height: 60vh;
        width: auto;
      } */
      .gallery {
        img {
          width: auto;
          height: 70px;
        }
      }
    }
  }
  @media (max-width: 430px) {
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
`;

export default ProductPage;
